import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';
import { ToasterService } from "./toaster.service";
import { WebstorageService } from "./webstorage.service";
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpResponse } from "@angular/common/http";
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from "@okta/okta-angular";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import * as _ from 'lodash';
import fileextension from "../../assets/meta/fileextenstion.json";
import { isLocalNetwork } from "../utils/utils";
import { TokenStorage, Tokens, UserManager } from "@forgerock/javascript-sdk";
import * as Util from '../utils/utils';

export enum EVENTSOURCE {
    INPROGRESS = "inprogress",
    SUCCESS = "success",
    ERROR = "error",
    INITIAL = "initial",
    OPEN = "open",
    CANCELLED = "cancelled"
}
@Injectable()
export class GatewayService {

    public selectedTenantID = environment._TenantID;
    public selectedPartnerID = environment._PartnerID;
    public baseURL = '';
    public clientID = '';
    public token = '';
    public loggedInUser = '';

    userUpdate = new BehaviorSubject({});
    userUpdateData = this.userUpdate.asObservable();

    tenantData = new Subject<any>();
    tenantDataObs = this.tenantData.asObservable();

    tenantDataRefresh = new Subject<any>();
    tenantDataRefreshObs = this.tenantDataRefresh.asObservable();

    updateLanguage = new Subject<any>();
    updateLanguageObs = this.updateLanguage.asObservable();

    dashboardObj = {
        parent: {
            to: null,
            from: null,
            selection: 'All',
            loading: true,
            isError: false
        },
        userSummary: {
            to: null,
            from: null,
            selection: null,
            loading: true,
            isError: false
        },
        groupSummary: {
            to: null,
            from: null,
            selection: null,
            loading: true,
            isError: false
        },
        usersChart: {
            to: null,
            from: null,
            selection: null,
            loading: true,
            isError: false
        },
        operationalSummary: {
            to: null,
            from: null,
            selection: null,
            loading: true,
            isError: false
        }
    }

    groupsFilterChange = new Subject();
    isGroupsFilterChange = this.groupsFilterChange.asObservable();

    groupsDataUpdated = new Subject();
    isGroupsDataUpdated = this.groupsDataUpdated.asObservable();


    constructor(public toast: ToasterService, public storage: WebstorageService, public http: HttpClient, public router: Router, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private msalService: MsalService) {
        this.getUserDetails();
     }

     async getUserDetails() {
        if(environment.DIRECTORY === 'Azure') {
            if (!_.isNull(this.msalService.instance.getActiveAccount())) {
                const userDetails = this.msalService.instance.getActiveAccount();
                this.loggedInUser = userDetails.username;
             }
        } else if (environment.DIRECTORY === 'Okta') {
            this.oktaAuth.getUser().then((res)=> {
                this.loggedInUser = res.email;
            });
        } else if (environment.DIRECTORY === 'ForgeRock DS' ||  environment.DIRECTORY === 'ForgeRock_IM' || environment.DIRECTORY === 'ForgeRock_IC') {
            const userinfo = await UserManager.getCurrentUser();
            this.loggedInUser = userinfo["name"];
        }
    }

    public getHeader(accessToken) {
        let _headers;
        return _headers = new HttpHeaders().set('Authorization', 'Bearer ' + accessToken);
    }

    getAccessToken(isTenantCall?): any {
        return new Promise(async (resolve) => {
            if (environment.DIRECTORY === 'Okta') {
                //return this.oktaAuth.getAccessToken();
                this.token = this.oktaAuth.getAccessToken();
                if (isTenantCall) {
                    resolve(this.oktaAuth.getAccessToken());
                    return;
                }
                if (this.token && this.selectedTenantID) {
                    resolve(this.oktaAuth.getAccessToken());
                }
            } else if (environment.DIRECTORY === 'Azure') {
                this.msalService.instance.handleRedirectPromise().then(res => {
                    let tokenRequest = {
                        scopes: [],
                        clientId: environment.AD_CONFIG['azure']['redirectUri']
                    };
                    this.msalService.instance.acquireTokenSilent(tokenRequest).then((res) => {
                        this.token = res.accessToken;

                        if (isTenantCall) {
                            resolve(res.accessToken);
                        }

                        if (this.token && this.selectedTenantID) {
                            resolve(res.accessToken);
                        }
                    })
                })
            } else if (environment.DIRECTORY === 'ForgeRock DS' ||  environment.DIRECTORY === 'ForgeRock_IM' || environment.DIRECTORY === 'ForgeRock_IC') {
                const tokens: Tokens = await TokenStorage.get();
                this.token = tokens.idToken;

                if (isTenantCall) {
                    resolve(tokens.idToken);
                }
                if (this.token && this.selectedTenantID) {
                    resolve(tokens.idToken);
                }
            }
        })
    }

    setTenantId(resolve, token) {
        this.http.get(environment.PARTNERAPI + environment.TENANT_API,
            {
                headers: this.getHeader(this.token)
            }
        ).subscribe((response) => {
            if (response[0]) { this.toast.error("Unable to get the Tenant"); }
            if (response[1]) {
                //const client = environment.TENANT_NAME;
                let client = (location.host).split(/:|\./)[0];

                if (Util.isLocalNetwork(client)) {
                    client = environment.CLIENT_FOR_LOCALHOST;
                }

                const tenantInfo = response[1].filter(el => el.tenantName.toLowerCase() === client);
                this.selectedTenantID = (tenantInfo.length) ? tenantInfo[0].tenantID : null;
                resolve(token);
            }
        }, (err) => {
            this.toast.error('Error Fetching tenant');
        }
        );
    }

    protected removeEmptyString(map: any) {           /* Remove the empty string parameter [Dynamo doesnot support empty string] */

        for (let key of Object.keys(map)) {

            if (map[key] === "" || map[key] === null) {
                delete map[key];
            }
            else if (map[key] != "" || map[key] != null) {
                var innerKey = map[key];
                if (typeof (innerKey) == "object") {
                    this.removeEmptyString(innerKey);
                }
            }
        }
    }

    public getTenant = function getTenant(callback): void {
        this.getAccessToken(true).then((token) => {
            let client = (location.host).split(/:|\./)[0];

            if (Util.isLocalNetwork(client) || client.includes('localhost')) {
                client = environment.CLIENT_FOR_LOCALHOST;
            }
            
            const organization = client ? `?organization=${client}`: '';
            
            this.http.get(environment.PARTNERAPI + environment.TENANT_API + organization,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(
                    (Response) => {
                        if (Response[0]) { this.toast.error("Unable to get the Tenant"); }
                        callback(null, Response[1]);
                    }
                    , err => callback(err, null)
                );
        });

    }

    public getAllPartnerUsers = function getAllPartnerUsers(callback): void {

        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })

    }

    public getUserData = function getUserData(user, callback): void {

        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users?userName=" + encodeURIComponent(user) + "&tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })

    }

    public getPartnerUserData = function getPartnerUserData(id, callback): void {

        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users/" + id + "?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })

    }


    public getAutomationDetails = function getAutomationDetails(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.PARTNERAPI + environment.TENANT_API + "/" + this.selectedTenantID + "/automationdetails" + "?partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )

                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })

    }

    getResourceTypes(token): Observable<any> {
        // if (environment.DIRECTORY === 'Okta') {

        // } else {
        //     return this.http.get(environment.BASEAPI + '/ResourceTypes?tenantid=' + this.selectedTenantID, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
        // }

        return this.http.get(environment.BASEAPI + '/ResourceTypes?tenantid=' + this.selectedTenantID + "&partnerId=" + environment._PartnerID, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })

    }

    getSchemas(token): Observable<any> {
        // if (environment.DIRECTORY === 'Okta') {

        // } else {
        //     return this.http.get(environment.BASEAPI + '/Schemas?tenantid=' + this.selectedTenantID, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
        // }

        return this.http.get(environment.BASEAPI + '/Schemas?tenantid=' + this.selectedTenantID + "&partnerId=" + environment._PartnerID, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })

    }

    public getSelectedAttributesFromTemplate = function getSelectedAttributesFromTemplate(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Attributes?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getSelectedGroupsFromTemplate = function getSelectedGroupsFromTemplate(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Groups?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public createUser = function createUser(body: any, callback): void {
        this.getAccessToken().then((token) => {
            this.http.post(
                environment.BASEAPI + "/Users?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                body,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(Response[0], Response[1]), err => callback(err, null));
        })
    }

    public updateUser = function updateUser(id, body: any, callback): void {
        this.getAccessToken().then((token) => {
            this.http.put(
                environment.BASEAPI + "/Users/" + id + "?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                body,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(Response[0], Response[1]), err => callback(err, null));
        })
    }

    public deleteUser = function deleteUser(userId: any, callback): void {
        this.getAccessToken().then((token) => {
            this.http.delete(
                environment.BASEAPI + "/Users/" + userId + "?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public initiateDownloadCsv = function initiateDownloadCsv(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users/download/initiate?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public cancelDownload = function cancelDownload(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users/download/stop?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    responseType: "text/csv",
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getDownloadStatus = function getDownloadStatus(callback) {
        this.getAccessToken().then((token) => {
            if (typeof (EventSource) !== undefined) {
                let source = new EventSource(environment.BASEAPI + "/Users/download/status?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID + "&token="+ token + "&ngsw-bypass=true");
                source.addEventListener('message', (event) => {
                    if (event.eventPhase == EventSource.CLOSED) {
                        let responseData = event.data.toLowerCase().trim();
                        if (responseData.includes(EVENTSOURCE.SUCCESS) || responseData === EVENTSOURCE.CANCELLED) {
                            source.close();
                        }
                        callback(null, responseData);
                    }
                });
                source.addEventListener('error', (event) => {
                    source.close();
                    callback(event, null);
                });
            } else {
                callback('Download Users Failed', null);
            }
        })
    }

    public downloadUsersCSV = function downloadUsersCSV(callback) {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/Users/download?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    responseType: "text/csv",
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public uploadUserData = function uploadUserData(file, selectedFile, fileName, progress, callback) {

        this.getAccessToken().then((token) => {

            let extension = "." + (selectedFile.name.split('.').pop()).toLowerCase();
            let selectedFileType = this.getFileType(extension, selectedFile);

            this.http.post(
                environment.BASEAPI + "/Users/upload?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                { type: selectedFileType, file: file, fileName: fileName },
                {
                    headers: new HttpHeaders({
                        "Authorization": "Bearer " + token,
                        "ngsw-bypass": "true"
                    }),
                    reportProgress: true,
                    observe: "events"
                }
            )


                .subscribe((event: HttpEvent<any>) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // This is an upload progress event. Compute and show the % done:
                        progress["percentage"] = Math.round(100 * event.loaded / event.total);
                    } else if (event instanceof HttpResponse) {
                        progress.percentage = 100;
                        callback(null, event);
                    }
                }, err => callback(err, null));

        });
    }

    private getFileType(extension, selectedFile) {
        let filter: any = fileextension.filter(file => file.extension === extension);

        if (filter.length > 0 && filter[0].type !== selectedFile.type) {                 // FIX: windows msexcel opens csv file with ectension as XL file reader
            return filter[0].type;
        } else {
            return selectedFile.type;
        }
    }

    public getUploadHistroy = function getUploadHistroy(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/users/upload/history?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getUploadHistroyById = function getUploadHistroyById(reqObj, callback): void {
        let queryParams = "";
        if (reqObj?.id) {
            queryParams += "/" + reqObj.id + "?";
        }
        if (reqObj?.startIndex) {
            queryParams += "startIndex=" + reqObj.startIndex + "&count=50&"
        }
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/users/upload/history" + queryParams + "tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getUploadHistoryStatus = function getUploadHistoryStatus(fileId, callback) {
        this.getAccessToken().then((token) => {
            this.http.get(
                `${environment.BASEAPI}/users/upload/history/usersstatus/${fileId}?tenantid=${this.selectedTenantID}&partnerId=${environment._PartnerID}`,
                {
                    responseType: "text/csv",
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    getAttributeValues(callback) {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/PartnerDetails?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }


            )
                .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    // Dashboard 

    getPartnerDetails(callback, startTime?, endTime?) {
        this.getAccessToken().then(accessToken => {
            let queryString = '';
            if (startTime && endTime) {
                queryString = '?startTime=' + startTime + '&endTime=' + endTime;
            }
            let url = environment.BASEAPI + "/dashboard/joiningdetails?tenantid=" + this.selectedTenantID + '&partnerId=' + environment._PartnerID;

            return this.http.get(url,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    getUsersSummaryData(callback, startTime, endTime) {
        this.getAccessToken().then(accessToken => {
            let queryString = '';
            if (startTime && endTime) {
                queryString = '&startTime=' + startTime + '&endTime=' + endTime;
            }
            let url = environment.BASEAPI + "/dashboard/userssummary?tenantid=" + this.selectedTenantID + '&partnerId=' + environment._PartnerID + '' + queryString;
            return this.http.get(url,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    getGroupsSummaryData(callback, startTime?, endTime?, isDateRangeChangeFromDailog?) {
        this.getAccessToken().then(accessToken => {
            let queryString = '';
            if (startTime && endTime) {
                queryString = '&startTime=' + startTime + '&endTime=' + endTime;
            }
            let url = environment.BASEAPI + "/dashboard/groupssummary?tenantid=" + this.selectedTenantID + '&partnerId=' + environment._PartnerID + '' + queryString;
            
            return this.http.get(url,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    getOperationalSummaryData(callback, startTime?, endTime?) {
        this.getAccessToken().then(accessToken => {
            let queryString = '';
            if (startTime && endTime) {
                queryString = '&startTime=' + startTime + '&endTime=' + endTime;
            }
            let url = environment.BASEAPI + "/dashboard/operationalsummary?tenantid=" + this.selectedTenantID + '&partnerId=' + environment._PartnerID + '' + queryString;
            
            return this.http.get(url,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    getUsersGraphsSummaryData(callback, startTime?, endTime?, isDateRangeChangeFromDailog?){
        this.getAccessToken().then(accessToken => {
            let queryString = '';
            if (startTime && endTime) {
                queryString = '&startTime=' + startTime + '&endTime=' + endTime;
            }
            let url = environment.BASEAPI + "/dashboard/usersgraphssummary?tenantid=" + this.selectedTenantID + '&partnerId=' + environment._PartnerID + '' + queryString;
           
            return this.http.get(url,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response, isDateRangeChangeFromDailog), err => callback(err, null, isDateRangeChangeFromDailog));
        });
    }

    public getAdminsList = function getAdminsList(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                environment.BASEAPI + "/admins?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                {
                    headers: this.getHeader(token)
                }
            )
            .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public addAdminToList = function addAdminToList(body, callback): void {
        this.getAccessToken().then((token) => {
            this.http.put(
                environment.BASEAPI + "/admins?tenantid=" + this.selectedTenantID + "&partnerId=" + environment._PartnerID,
                body,
                {
                    headers: this.getHeader(token)
                }
            )
            .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getSubOrganizations = function getSubOrganizations(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${environment._PartnerID}/listsuborganizations?partnerId=${environment._PartnerID}`,
                {
                    headers: this.getHeader(token)
                }
            )
            .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public createPartner = function createPartner(partnerData: any, callback): void {
        this.getAccessToken().then((accessToken => {
            partnerData.tenantId = environment._TenantID;
            partnerData.createdOn = partnerData.updatedOn = new Date().toISOString();
            partnerData.createdBy = partnerData.updatedBy = this.loggedInUser;
            this.http.post(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization?partnerId=${environment._PartnerID}`,
                partnerData,
                {
                    headers: this.getHeader(accessToken)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        }));

    }

    public updatePartner = function updatePartner(partnerData: any, callback): void {
        this.getAccessToken().then((token) => {
            partnerData.tenantId = environment._TenantID;
            partnerData.updatedOn = new Date().toISOString();
            partnerData.updatedBy = this.loggedInUser;
            this.http.put(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${partnerData.id}?partnerId=${environment._PartnerID}`,
                partnerData,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getTemplateDetails = function getTemplateDetails(callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${environment._PartnerID}/template?partnerId=${environment._PartnerID}`,
                {
                    headers: this.getHeader(token)
                }
            )
            .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public getSubOrganizationById = function getSubOrganizationById(partnerId ,callback): void {
        this.getAccessToken().then((token) => {
            this.http.get(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${partnerId}?partnerId=${environment._PartnerID}`,
                {
                    headers: this.getHeader(token)
                }
            )
            .subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public deleteSubOrganization = function deleteSubOrganization(organizationId: any, callback): void {
        this.getAccessToken().then((token) => {
            this.http.delete(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${organizationId}?partnerId=${environment._PartnerID}`,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        })
    }

    public uploadOrganizationLogos = function uploadOrganizationLogos(logoData, callback): void{
        this.getAccessToken().then(token => {
            this.http.post(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/logos`,
                logoData,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    public deleteOrganizationLogos = function deleteOrganizationLogos(logoData, callback): void {
        this.getAccessToken().then(token => {
            const url = `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/logos`;
            const options = {
                headers: this.getHeader(token),
                body: logoData
            };
    
            this.http.request('DELETE', url, options).subscribe(
                response => callback(null, response),
                error => callback(error, null)
            );
        });
    }

    public checkUserInDirectory = function checkUserInDirectory(data, callback): void{
        this.getAccessToken().then(token => {
            this.http.get(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${data.organizationId}/admins?email=${data.email}`,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    public createUserInDirectory = function createUserInDirectory(data, callback): void {
        const {email, firstName, lastName, isOkta} = data;
        this.getAccessToken().then(token => {
            this.http.post(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${data.organizationId}/admins`,
                {email, firstName, lastName, isOkta},
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    public checkUserOfCurrentOrganizationInDirectory = function checkUserInDirectory(data, callback): void{
        this.getAccessToken().then(token => {
            this.http.get(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${environment._PartnerID}/admins?email=${data.email}`,
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }

    public createUserOfCurrentOrganizationInDirectory = function createUserInDirectory(data, callback): void {
        const {email, firstName, lastName} = data;
        this.getAccessToken().then(token => {
            this.http.post(
                `${environment.PARTNERAPI}${environment.TENANT_API}/${this.selectedTenantID}/suborganization/${environment._PartnerID}/admins`,
                {email, firstName, lastName},
                {
                    headers: this.getHeader(token)
                }
            ).subscribe(Response => callback(null, Response), err => callback(err, null));
        });
    }
    
}